import React from 'react';

import { OneColumn } from '@components/Layouts/OneColumn';
import NotFound from '@components/Routing/NotFound';

import { FEATURE_LEADS_CANDIDATE_PROFILE, isEnabled } from '@utils/features';

const CandidateProfilePage: React.FC = () => {
  const isCandidateProfleEnabled = isEnabled(FEATURE_LEADS_CANDIDATE_PROFILE);
  if (!isCandidateProfleEnabled) {
    return <NotFound />;
  }
  return (
    <OneColumn>
      <div>Here will be future Candidate Profile Page</div>
    </OneColumn>
  );
};
export default CandidateProfilePage;
